<template>
    <el-dialog
        append-to-body
        title="添加标签"
        :visible="visible"
        width="800px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        custom-class="" @close="close()">
        <div class="step" :class="{'active':step==2}">
            <div class="bigLine"></div>
            <div class="info">提示信息确认</div>
            <div class="line"><span class="smallLine"></span><i class="el-icon-d-arrow-right"></i><span
                class="smallLine"></span></div>
            <div class="info">标签设置</div>
            <div class="bigLine"></div>
        </div>
        <template v-if="step==1">
            <div class="tips ">
                提示：批量标签受到单文件标签个数限制(<span>一个文件最多设置20个标签</span>)，本次批量标签<span>最多可添加{{ 20 - maxTagCount }}个</span>标签。
            </div>
            <el-table :data="tableData" max-height="200px" ref="table">
                <el-table-column prop="fileName" label="文件名称" show-overflow-tooltip></el-table-column>
                <el-table-column label="标签个数" align="center" width="120">
                    <template slot-scope="scope">
                        {{ scope.row.tagCount.length }}
                    </template>
                </el-table-column>
            </el-table>
            <div class="tips">
                <span>文件夹暂不支持设置标签</span>
            </div>
            <div class="btn">
                <el-button type="primary" @click="step=2">下一步</el-button>
            </div>
        </template>
        <template v-if="step==2">
            <div class="tips">本次批量标签目标对象最多可添加{{ 20 - maxTagCount }}个标签</div>
            <set-tag ref="setTagRef" :tagList="[]" @tagList="changeTag"></set-tag>
            <div class="btn">
                <el-button @click="step=1">上一步</el-button>
                <el-button type="primary" :loading="loading" @click="userSure()"  v-if="!!1">确 定
                </el-button>
                <el-button type="primary" @click="sure()"  v-else>确 定</el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
import setTag from "./setTag.vue";

export default {
    name: "editTag",
    components: {
        setTag,
    },
    data() {
        return {
            step: 1,
            maxTagCount: 0,
            tagList: [],
            tableData: [],
            path: '',
            bucketName: '',
            fileInfo: [],
            visible: false,
            regionId: '',
            isMoreBucket: false,
            loading: false
        };
    },
    created() {
        // if (this.bucketName) {
        //     this.tagInfo({
        //         bucketName: this.bucketName,
        //         fileList: this.fileInfo.map((item) => item.fileName),
        //         path: this.path,
        //     }).then((res) => {
        //         this.tableData = res.data.data;
        //         var tagCount = res.data.data.map((item) => item.tagCount.length);
        //         this.maxTagCount = Math.max.apply(null, tagCount);
        //     });
        // } else {
        //     var tagCount = this.fileInfo.map((item) => item.tagCount.length);
        //     this.maxTagCount = Math.max.apply(null, tagCount);
        //     this.tableData = JSON.parse(JSON.stringify(this.fileInfo));
        // }
    },
    methods: {
        show (fileInfo, bucketName, regionId) {
            this.fileInfo = fileInfo
            this.isMoreBucket = false
            bucketName ? (this.bucketName = bucketName) : (this.isMoreBucket = true)
            this.visible = true
            let data = {
                keyAndVersionIdList: fileInfo.map(i => ({bucketName: bucketName || i.bucket,key: i.key, versionId: null, })),
            }
            this.$postJson('/file/getTaggingBatch', data)
                .then(res => {
                    if (res.code === '200') {
                        let keys = Object.keys(res.data), list = []
                        keys.forEach(key => {
                            let kb = key.split('_')
                            list.push({
                                fileName: kb[1],
                                bucketName: kb[0],
                                tagCount: res.data[key]
                            })
                        })
                        this.tableData = list
                        let tagCount = list.map(i => i.tagCount.length)
                        this.maxTagCount = Math.max.apply(null, tagCount);
                    }
                })
        },
        close() {
            this.visible=false
        },
        changeTag(val) {
            this.tagList = val;
        },
        userSure() {
            if (this.loading) {
                return;
            }
            if (this.tagList.length == 0) {
                this.$message.error(`请至少添加一个标签`);
                return;
            }
            if (this.tagList.length + this.maxTagCount > 20) {
                this.$message.error(
                    `本次批量标签目标对象最多可添加${20 - this.maxTagCount}个标签`
                );
                return;
            }
            this.loading = true
            if (this.isMoreBucket) {
                let data = {
                    keyAndVersionIdList: this.fileInfo.map(i => ({key: i.name, versionId: i.versionId || null, bucketName: i.bucket})),
                    tagList: this.tagList,
                }
                //
                this.$postJson('/file/addTaggingDifBucket', data)
                    .then(res => {
                        if (res.code === '200') {
                            this.$message.success("添加标签成功");
                            this.visible = false
                            this.$refs.setTagRef.init()
                            this.$emit('complete')
                        }
                    })
                .finally(() => { this.loading = false })
            } else {
                let data = {
                    bucketName: this.bucketName,
                    tagList: this.tagList,
                    keyAndVersionIdList: this.fileInfo.map(i => ({key: i.key, versionId: i.versionId || null}))
                }
                this.$postJson('/file/addTagging', data)
                    .then(res => {
                        if (res.code === '200') {
                            this.$message.success("添加标签成功");
                            this.$refs.setTagRef.init()
                            this.visible = false
                            this.$emit('complete')
                        }
                    })
                    .finally(() => { this.loading = false })
            }

            // this.addTag({
            //     bucket: this.bucketName,
            //     fileList: this.fileInfo.map((item) => item.fileName),
            //     path: this.path,
            //     tagSet: this.tagList,
            // }).then((res) => {
            //     this.$message.success("添加标签成功");
            //     this.close();
            // });
        },
        sure() {
            if (this.tagList.length + this.maxTagCount > 20) {
                this.$message.error(
                    `本次批量标签目标对象最多可添加${20 - this.maxTagCount}个标签`
                );
                return;
            }
            var fileGroup = [];
            this.fileInfo.map((item) => {
                fileGroup.push({
                    [item.bucketName]: item.fileRoad.slice(1) + item.fileName,
                });
            });
            this.batchDiff({
                fileGroup: fileGroup,
                tagSet: this.tagList,
            }).then((res) => {
                this.$message.success("操作成功");
                this.close();
                this.$emit("sure");
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.step {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.bigLine {
    width: 84px;
    height: 2px;
    background: #cccccc;
}

.bigLine:nth-of-type(1) {
    background: #006bff;
}

.info {
    width: 124px;
    height: 32px;
    line-height: 28px;
    text-align: center;
    font-size: 14px;
    color: #888;
    border: 2px solid #ccc;
    border-radius: 18px;
    margin: 0 4px;
}

.info:nth-of-type(2) {
    color: #fff;
    border-color: #006bff;
    background: #006bff;
}

.line {
    display: flex;
    align-items: center;
}

.smallLine {
    width: 34px;
    height: 2px;
    background: #cccccc;
    display: inline-block;
    margin: 0 2px;
}

.iconshuangjiantouyou {
    color: #ccc;
}

.step.active .bigLine,
.step.active .smallLine {
    background: #006bff;
}

.step.active .info:nth-child(2) {
    color: #006bff;
    background: #fff;
    border-color: #006bff;
}

.step.active .info:nth-child(4) {
    color: #fff;
    border-color: #006bff;
    background: #006bff;
}

.step.active .iconshuangjiantouyou {
    color: #006bff;
}

.tips {
    color: #393a42;
    margin-bottom: 10px;
}

.tips span {
    color: red;
}

.btn {
    text-align: center;
    margin-top: 20px;
}
</style>
