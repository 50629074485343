<template>
    <el-dialog
        title="编辑标签"
        :visible="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        append-to-body
        :destroy-on-close="true"
        @close="close()">
        <div >
            <div class="tips">一个对象最多设置20个标签</div>
            <set-tag ref="setTagRef" :tagList="defaultTagList" @tagList="changeTag" v-if="isShow"></set-tag>
        </div>
        <div slot="footer">
            <el-button size="small" @click="close()">取 消</el-button>
            <el-button size="small" type="primary" @click="sure()" :loading="loading">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
import setTag from './setTag.vue'

export default {
    name: 'editTag',
    components: {
        setTag,
    },
    data() {
        return {
            defaultTagList: [],
            tagList: [],
            isShow: false,
            visible: false,
            fileInfo: {},
            loading: false
        }
    },

    methods: {
        // 单个文件
        show (file) {
            this.visible = true
            this.fileInfo = file || {}
            this.getAllTags()
            this.getTag()
        },
        show2 () {},
        close() {
            this.$refs.setTagRef.reset()
            this.isShow = false
            this.visible = false

        },
        getTag() {
            const { fileInfo } = this
            let data = {
                bucketName: fileInfo.bucketName,
                key: fileInfo.key,
                versionId: fileInfo.versionId,
                regionId: fileInfo.regionId
            }
            this.$postJson('/file/getTagging', data)
            .then(res => {
                if (res.code === '200') {
                    this.defaultTagList = res.data
                }
            })
                .finally((res) => {
                    this.isShow = true
                })

        },
        getAllTags () {},
        changeTag(val) {
            this.tagList = val
        },
        sure() {
            if (this.tagList.length > 20) {
                this.$message.error('一个对象最多设置20个标签')
                return
            }
            if (this.loading) {
                return;
            }
            this.loading = true

            let data = {
                tagList: this.tagList,
                bucketName: this.fileInfo.bucketName,
                key: this.fileInfo.key,
                versionId: this.fileInfo.versionId,
                regionId: this.fileInfo.regionId
            }
            this.$postJson('/file/updateTagging', data)
                .then((res) => {
                    this.$message.success('编辑标签成功，数据显示平均延迟1分钟，请耐心等待')
                    this.close()
                    this.$emit('complete')
                })
            .finally(() => {
                this.loading = false
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.tips {
    color: red;
    margin-bottom: 10px;
}
</style>
