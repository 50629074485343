<template>
    <el-drawer :visible.sync="drawer" @close="closeDrawer()" :modal="false" class="">
        <div class="fileDetail">
        <el-tabs v-model="activeName" v-loading="loading">
            <el-tab-pane label="文件详情" name="first">
                <div class="fileDetail_box">
                    <div class="basic_content">
                        <div class="fileDetail_item">
                            <span class="b_left">文件名称</span><span class="b_right" :title="fileDetail.displayName">{{
                                fileDetail.displayName
                            }}</span>
                        </div>
                        <div class="fileDetail_item">
                            <span class="b_left">存储类型</span>
                            <span v-if="fileDetail?.meta.storageClass === 'STANDARD'" class="b_right">标准存储</span>
                            <span v-if="fileDetail?.meta.storageClass === 'INTELLIGENT'" class="b_right">智能存储</span>
                            <span v-if="fileDetail?.meta.storageClass === 'ARCHIVE'" class="b_right">归档存储</span>
                            <span v-if="fileDetail?.meta.storageClass === 'GLACIER'" class="b_right">深度归档存储</span>
                        </div>
                        <div class="fileDetail_item">
                            <span class="b_left">文件大小</span><span class="b_right">{{
                                bytesToSize(fileDetail.size)
                            }}</span>
                        </div>
                        <template v-if="fileDetail.meta.storageClass === 'GLACIER'">
                            <div class="fileDetail_item" v-for="(item, index) in fileLocations" :key="index">
                                <span class="b_left">{{ index === 0 ? '文件' : '副本' }}位置
                                    <el-tooltip class="item" effect="dark"
                                                :content="`显示${index === 0 ? '文件' : '副本'}的实际存储位置`"
                                                placement="top-start"><i class="el-icon-info"></i>
                                    </el-tooltip>
                                </span>
                                <div class="b_right">
                                    <div>
                                        <p v-if="glacierType!='1'">所属盘库：{{ item.serial_no }}<br/></p>
                                        <p>盘匣组：{{ item.library }}</p>
                                        <p v-if="glacierType!='1'">盘匣ID：{{ item.devices.map(i => ((i.name && i.name.indexOf('_') > -1) ? i.name.split('_')[0] : i.name)).join(',') }}</p>
                                        <p v-if="item.bucket_tag">条形码：{{ item.bucket_tag }}</p>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div class="fileDetail_item">
                            <span class="b_left">更新时间</span><span class="b_right">{{ moment(fileDetail.lastModified).format('YYYY-MM-DD HH:mm:ss') }}</span>
                        </div>
<!--                        <div class="fileDetail_item">-->
<!--                            <span class="b_left">取回过期时间</span><span-->
<!--                            class="b_right">{{ fileDetail.expire_date == "" ? "-" : fileDetail.expire_date }}</span>-->
<!--                        </div>-->
                        <div class="fileDetail_item">
                              <span class="b_left">访问地址
                                <span class="down_url" v-if="fileUrl != ''" @click="download(fileUrl)">下载</span>
                                <span class="copy_url" v-if="fileUrl != ''" @click="copy()"
                                      :data-clipboard-text="fileUrl">复制</span>
                              </span>
                            <span class="b_right" :title="fileUrl">{{ fileUrl }}</span>
                        </div>
                        <div class="fileDetail_item">
                            <span class="b_left">自定义标签 <span style="cursor: pointer;"><i class="iconfont2 iconbianji" @click="editTag()"/></span></span>
                            <div class="fileDetail_tag_div">
                                <el-tag size="small" class="fileDetail_tag" v-for="item in fileDetail.tagSet"
                                        :key="item" :value="item">
                                    {{ item }}
                                </el-tag>

                            </div>
                        </div>
                        <div v-if="tagshow" class="fileDetail_item">
                            <span class="b_left">自动标签</span>
                            <div class="fileDetail_tag_div">
                                <el-tag class="fileDetail_tag" v-for="item in fileDetail.AutoTagSet" :key="item"
                                        :value="item">
                                    {{ item }}
                                </el-tag>
                            </div>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
            <!-- <el-tab-pane label="文件ACL" name="second" v-if="aclShow">
              <div class="fileDetail_box">
                <div class="fileDetail_item">
                  <span class="b_left">公共权限</span>
                  <div class="b_right file_acl">
                    <el-radio-group v-model="radio" @change="changAcl(radio)">
                      <el-radio :disabled="isOut" label="private">私有</el-radio>
                      <el-radio :disabled="isOut" label="public-read">公共读</el-radio>
                      <el-radio label="public-read-write">公共读写</el-radio>
                    </el-radio-group>
                  </div>
                  <div class="acl_tips">注：若桶ACL为私有，文件ACL为公共，则其他用户无法通过界面化形式对文件进行操作，但仍可通过命令行的形式对文件进行操作</div>
                </div>
              </div>
            </el-tab-pane> -->
            <el-tab-pane v-if="isImg" label="图片预览" name="third">
                <div class="fileDetail_box">
                    <span class="b_left img_style">图片样式</span>
                    <el-select v-model="imgStyle" placeholder="请选择" @change="selectChange(imgStyle)">
                        <el-option v-for="item in options" :key="item.bid" :label="item.styleName"
                                   :value="item.bid">
                        </el-option>
                    </el-select>

                    <el-button class="f_right" size="small" @click="refresh(1)">刷新</el-button>
                    <el-button class="f_right" size="small" @click="refresh(2)">下载</el-button>
                </div>
                <div class="fileDetail_box">
                    <span class="b_left img_style">预览效果</span>
                    <div class="show_main">
                        <el-image style="width: 100%; height: 100%" :src="imgSrc" :preview-src-list="srcList"
                                  v-loading="imgLoading" fit="scale-down" ref="image"></el-image>
                    </div>
                    <div class="operate"><span @click="previewBig">查看大图</span></div>
                </div>
            </el-tab-pane>
        </el-tabs>
        <edit-tag ref="editTagRef" @complete="getTag"/>
        </div>
    </el-drawer>
</template>
<script>
import {formatBytes} from '@/utils/utils2'
import storage, {set, get} from '@/utils/storage'
import Clipboard from 'copy-to-clipboard'
import editTag from './editTag'
import {bytesToSize} from "@/utils";
import {addMeta} from "@/utils/session";
import moment from "moment";

export default {
    name: 'fileDetail',
    components: {
        editTag,
    },
    props: {},
    data() {
        return {
            bucketName: '',
            path: '',
            bucketType: '',
            fileDetail_name: '',
            drawer: false,
            isOut: true,
            imgLoading: true,
            num: '',
            aclShow: false,
            ischange: false,
            activeName: 'first',
            fileDetail: {meta: {}},
            radio: 'private',
            options: [
                {
                    styleName: '原图',
                    bid: '',
                },
            ],
            imgStyle: '',
            imgSrc: '',
            srcList: [],
            isImg: false,
            tagshow: false,
            editTagShow: false,
            tagInfo: {},
            loading: false,
            appPageize: 10,

            /**
             *     state.glacierType = userInfo.glacierType;
             *     storage.set("glacierType", userInfo.glacierType);
             *     0.华录蓝光 1.GDAS  2.索尼蓝光(暂定)
             * **/
            glacierType: '',
            fileUrl: '',
        }
    },
    computed: {
        initFilePath() {
            return this.path + this.fileDetail_name
        },
        fileLocations() {
            if (!this.fileDetail.meta) {
                return []
            }
            let file_locations = this.fileDetail.meta.rawMetadata.x_amz_archive_location ? JSON.parse(this.fileDetail.meta.rawMetadata.x_amz_archive_location) : []
            return file_locations.sort((a, b) => (a.isPrimary ? -1 : 1))
        },
    },
    created() {
        // this.getFile()
    },
    methods: {
        // ...mapActions('bucketManage', ['fileInfo', 'changeFileAcl', 'getImgList', 'imgShow']),
        bytesToSize,
        moment,
        show(file) {
            this.activeName = 'first'
            this.drawer = true
            this.fileDetail = file
            this.bucketName = file.bucketName
            const data = {bucketName: file.bucketName, key: file.key};
            this.$postJson("/file/meta", data).then((res) => {
                this.fileDetail.meta = res.data.meta
            })

            const reg = /\.(png|jpg|bmp|jpeg|webp|PNG|JPG|BMP|JPEG|WEBP)$/
            //自动标签为空不显示，文件不为图片不显示，文件没有url不显示
            if (reg.test(file.key)) {
                if (file.status !== '3') {
                    this.isImg = false
                } else {
                    this.isImg = true
                    this.getImgStyleList()
                    this.refresh(1)
                }
            } else {
                this.isImg = false
            }

            if (file.status === '3') {
                this.generateObjectUrl()
            }

            this.getTag()
        },
        getImgStyleList() {
            const data = {
                bucketName: this.fileDetail.bucketName,
                pageSize: 999,
                pageNum: 1,
            }
            this.$postJson('/imageStyles/pagelist', data)
                .then(res => {
                    if (res.code === '200') {
                        this.options = [{
                            styleName: '原图',
                            bid: '',
                        }, ...res.data.records]
                    }
                })
        },


        // 获取标签
        getTag() {
            const { fileDetail } = this
            let data = {
                bucketName: fileDetail.bucketName,
                key: fileDetail.key,
                versionId: fileDetail.versionId || null,
            }
            this.$postJson('/file/getTagging', data)
                .then(res => {
                    if (res.code === '200') {
                        this.fileDetail = {...this.fileDetail, tagSet: res.data}
                    }
                })
                .finally((res) => {
                })

        },

        generateObjectUrl() {
            const {bucketName, key} = this.fileDetail;
            let data = {
                bucketName,
                key: key,
                seconds: 900,
                versionId: null,
            }
            this.$postJson('/file/generateObjectUrl', data)
                .then(res => {
                    if (res.code === '200') {
                        this.fileUrl = res.data;
                    }
                })
        },

        closeDrawer() {
            this.drawer = false
        },
        getFile() {
            // this.activeName = 'first'
            this.fileInfo({
                bucketName: this.bucketName,
                filename: this.initFilePath,
            }).then((res) => {
                this.fileDetail = res.data
                // if (res.data.acl == '3') {
                //   this.aclShow = true
                //   this.radio = 'private'
                // } else if (res.data.acl == '1') {
                //   this.aclShow = true
                //   this.radio = 'public-read'
                // } else if (res.data.acl == '0') {
                //   this.radio = 'public-read-write'
                //   this.aclShow = true
                // } else if (res.data.acl == '-1') {
                //   this.aclShow = false
                //   // this.activeName = 'second'
                // }
                // set('fileAcl', this.radio)
                //自动标签为空，不显示自动标签
                if (res.data.AutoTagSet.length == 0) {
                    this.tagshow = false
                } else {
                    this.tagshow = true
                }
                const reg = /\.(png|jpg|bmp|jpeg|webp|PNG|JPG|BMP|JPEG|WEBP)$/
                //自动标签为空不显示，文件不为图片不显示，文件没有url不显示
                if (get('bucketType') == 1) {
                    if (reg.test(this.initFilePath)) {
                        if (res.data.fileUrl == '') {
                            this.isImg = false
                        } else {
                            this.isImg = true
                            this.getImgStyleList()
                            this.refresh(1)
                            this.srcList.push(res.data.fileUrl)
                        }
                    } else {
                        this.isImg = false
                    }
                } else {
                    this.isImg = false
                }
            })
            this.isOut = this.bucketType == '1' ? false : true
        },


        selectChange() {
            this.refresh(1)
        },
        // 图片加效果
        refresh(val) {

            const data = {
                bucketName: this.fileDetail.bucketName,
                imageStyleBid: this.imgStyle,
                key: this.fileDetail.key
            }
            this.$postJson('/imageStyles/preview', data).then((res) => {
                if (val == 1) {
                    if (res.data) {
                        this.imgSrc = res.data.data
                        this.srcList.push(res.data.data)
                        this.imgLoading = false
                    } else {
                        this.imgLoading = true
                    }
                } else {
                    const link = document.createElement('a')
                    link.href = res.data.data
                    link.download = res.data.fileName
                    var evt = document.createEvent('MouseEvents')
                    evt.initEvent('click', true, true)
                    link.dispatchEvent(evt)
                    window.URL.revokeObjectURL(link.href)
                }
            })
        },
        changAcl(val) {
            let str = ''
            let info = ''
            if (val == 'private') {
                str = '私有'
                info = '私有权限：其他人对该对象无访问权限。'
            } else if (val == 'public-read') {
                str = '公共读'
                info = '公共读权限：其他人对对象进行列出、下载、取回等操作。'
            } else if (val == 'public-read-write') {
                str = '公共读写'
                info = '公共读权限：其他人可对对象进行列出、下载、取回、删除、修改标签等操作。'
            }
            this.$confirm('<h4>确定要更改为' + str + '权限吗？</h4><p>' + info + '</p>', '公共权限', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                dangerouslyUseHTMLString: true,
            })
                .then(() => {
                    const data = {
                        bucketName: this.bucketName,
                        filename: this.initFilePath,
                        acl: this.radio,
                    }
                    data.sign = objEncrypt(data)
                    this.changeFileAcl(data).then((res) => {
                        this.$message({
                            type: 'success',
                            message: '修改成功!',
                        })
                        set('fileAcl', this.radio)
                    })
                })
                .catch(() => {
                    this.radio = get('fileAcl')
                })
        },
        download(val) {
            window.open(val, '_blank')
        },
        copy() {
            Clipboard(this.fileUrl)

            this.$message.success('已复制')
            // var clipboard = new Clipboard('.copy_url')
            // clipboard.on('success', (e) => {
            //     this.$message({
            //         type: 'success',
            //         message: '复制成功!',
            //     })
            //     clipboard.destroy()
            // })
            // clipboard.on('error', (e) => {
            //     clipboard.destroy()
            // })
        },
        formatBytes(val) {
            return formatBytes(val)
        },

        editTag() {
            this.$refs.editTagRef.show(this.fileDetail)
        },
        getList() {
            this.getFile()
        },
        previewBig() {
            this.$refs.image.clickHandler()
        },
    },
}
</script>
<style lang="scss" scoped>
.fileDetail {
    width: 100%;

    /deep/ {
        .el-tabs__nav-scroll {
            padding: 0 40px;
        }
    }
}

.fileDetail_box {
    background: white;
    padding: 10px 20px;
}

.fileDetail_item {
    margin-bottom: 20px;

    .file_acl {
        margin-top: 0.625rem;
    }

    .iconfont {
        margin-left: 10px;
        cursor: pointer;
    }

    .fileDetail_tag_div {
        width: 80%;
    }
}

.b_left {
    display: block;
    color: #888;
    font-size: 14px;
    // margin-right: 30px;
    line-height: 1.875rem;
}

.b_right {
    display: block;
    color: #222b45;
    font-size: 14px;
    line-height: 1.875rem;

    display: flex;
    align-items: center;
    word-break: break-all;

    span {
        display: inline-block;
        width: 80%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.copy_url {
    font-size: 14px;
    color: #006bff;
    cursor: pointer;
    float: right;
}

.down_url {
    font-size: 14px;
    color: #006bff;
    float: right;
    margin-left: 0.625rem;
    cursor: pointer;
}

.img_style {
    margin-bottom: 0.625rem;
}

.f_right {
    float: right;
    margin-left: 10px;
}

.show_main {
    width: 100%;
    height: 30rem;
    margin-top: 20px;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.1);
}

.fileDetail_tag {
    margin-right: 10px;
    margin-top: 5px;
}

.iconbianji {
    color: #006bff;
    vertical-align: middle;
}

.acl_tips {
    color: #9aa0ba;
    font-size: 0.875rem;
    margin-top: 10px;
}

.operate {
    line-height: 32px;
    text-align: center;
    color: #006bff;
    font-size: 16px;

    span {
        cursor: pointer;

        &:hover {
            opacity: 0.75;
        }
    }
}
</style>
